.group-service-listing {
  width: 100%;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
  padding-left: 32px;
  padding-right: 32px;
  background-color: white;
}

.group-service-listing th {
  padding: 12px 12px;
  border-bottom: 1px solid #e7ecef;
  text-align: left;
}

.group-service-listing td {
  padding: 12px 12px;
  text-align: left;
}

.group-service-listing tbody tr {
  cursor: pointer;
}

.group-service-listing .nowrap {
  white-space: nowrap;
}
.group-service-listing .fluid-cell {
  width: 10px;
}
.group-service-listing .forecast {
  width: 10px;
  text-align: right;
}
.group-service-listing .ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 0;
}

/*.service-header {*/
/*  position: -webkit-sticky;*/
/*  position: sticky;*/
/*  background-color: white;*/
/*  padding-top: 40px;*/
/*  padding-left: 32px;*/
/*  padding-right: 32px;*/
/*  top: 0;*/
/*  z-index: 20;*/
/*}*/
