.plan-management-listing {
  width: 100%;
  table-layout: auto;
}

.plan-management-listing th {
  position: -webkit-sticky;
  position: sticky;
  z-index: 20;
}

.plan-management-listing tbody tr {
  cursor: pointer;
}

.plan-management-header {
  /*position: -webkit-sticky;*/
  /*position: sticky;*/
  background-color: white;
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
  /*top: 0;*/
  /*z-index: 20;*/
}

.plan-management-tab .ant-tabs-bar {
  margin: 0;
}
