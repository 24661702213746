/* Booking List styles */
.booking-list-display-day {
  font-size: 28px;
  list-style-type: none;
  font-weight: 300;
  line-height: 28px;
}

.booking-list-display-month {
  font-size: 14px;
  list-style-type: none;
  font-weight: 300;
}

.booking-list-display-status {
  list-style-type: none;
  margin-top: 8px;
}

.booking-list-item-container {
  margin-top: 24px;
}

.booking-list-item-container:first-child {
  margin-top: 0px;
}

.booking-list-item-container:last-child {
  margin-bottom: 24px;
}
