.timepicker-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 18px;
  padding: 2px 8px 2px 4px;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s all;
}
.timepicker-container.default {
  font-size: 16px;
  padding: 2px 8px 2px 4px;
  width: 110px;
}
.timepicker-container.default .hour-input {
  width: 24px;
  text-align: center;
}
.timepicker-container.default .minutes-input {
  width: 22px;
}
.timepicker-container.default .ampm-input {
  width: 26px;
  text-align: center;
}
.timepicker-container.large {
  font-size: 18px;
  padding: 5px 10px 4px 6px;
  width: 123px;
}
.timepicker-container.large .hour-input {
  width: 25px;
  text-align: center;
}
.timepicker-container.large .hour-input:disabled, .hour-input[disabled] {
  background-color: #f8f9fa;
}

.timepicker-container.large .minutes-input {
  width: 25px;
}
.timepicker-container.large .minutes-input:disabled, .minutes-input[disabled] {
  background-color: #f8f9fa;
}

.timepicker-container.large .ampm-input {
  width: 28px;
  text-align: center;
}
.timepicker-container.large .ampm-input:disabled, .ampm-input[disabled] {
  background-color: #f8f9fa;
}
.timepicker-container.focused {
  outline: none;
  border-color: #2e82b0;
  box-shadow: 0 0 0 4px rgba(19, 124, 189, 0.2) !important;
}
.timepicker-container input {
  outline: none;
  border: none;
}
.timepicker-container input:focus {
  outline: none;
  text-shadow: 0 0 0 gray;
  background-color: #137cbd;
  color: white;
  border-radius: 4px;
}
.timepicker-container input::selection {
  background-color: #137cbd;
}
