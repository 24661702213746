.plan-payment-header {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
  top: 0;
  z-index: 20;
}
.plan-payment-item-count {
  position: sticky;
  bottom: 0;
  width: inherit;
  background-color: rgba(255, 255, 255, 0.93);
  min-height: 64px;
  padding: 16px 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.subLines tr {
  height: 20px;
}
.subLines td {
  border: none;
  padding-bottom: 6px;
}
.plan-payment-listing {
  width: 100%;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 100px; 
}
.plan-payment-listing th {
  padding: 12px 12px;
  border-bottom: 1px solid #ccc;
  text-align: left;
  /*position: -webkit-sticky;*/
  /*position: sticky;*/
  /*z-index: 20;*/
  background-color: white;
}
.plan-payment-listing td {
  border-bottom: 1px #EEEEEE solid; 
  padding: 12px 12px;
}  
.plan-payment-listing thead {
  background-color: white;
}
.plan-payment-listing tbody tr {
  cursor: pointer;
}
.plan-payment-listing .no-border td {
  border: 0;
}
.plan-payment-listing .nowrap { 
  white-space: nowrap;
}
.plan-payment-listing .fluid-cell {
  width: 10px;
}
.plan-payment-listing .forecast {
  width: 10px;
  text-align: right;
}
.plan-payment-listing .ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 0;
}
.plan-payment-listing .check-all {
  padding-left: 24px;
  padding-right: 24px;
}
.action-anim-slide-in {
  animation: actionSlideInAnimation 0.2s;
  animation-timing-function: ease-in-out;
}
.action-anim-slide-out {
  animation: actionSlideOutAnimation 0.1s;
  animation-timing-function: ease-in-out;
}
@keyframes actionSlideInAnimation {
  from {
    opacity: 0;
    transform: translateY(80px);
    -moz-transform: translateY(64 80px);
    -webkit-transform: translateY(64 80px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
}
@keyframes actionSlideOutAnimation {
  from {
    opacity: 1;
    transform: translateY(0px);
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(80px);
    -moz-transform: translateY(80px);
    -webkit-transform: translateY(80px);
  }
}
/*# sourceMappingURL=plan-payment-listing.css.map */