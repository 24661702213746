/* Blueprint js overrides */
/* Use this to globally override the blueprint js global css.  */

.bp3-html-table thead tr th {
  padding: 12px 8px;
}

.bp3-html-table tbody tr td {
  padding: 12px 8px;
}

.white-tooltip + div .bp3-popover2-content {
  background: #ffffff !important;
  color: #f5f8fa !important;
}

.white-tooltip + div .bp3-popover2-arrow-fill {
  fill: #ffffff !important;
}
